<template>
  <RouterView />
</template>

<script setup>
import { RouterView } from "vue-router";
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;

  &>.common-layout {
    height: 100% !important;
    // height: calc(100% - 88px) !important;

    .el-container {
      height: 100%;
    }
  }
}

.el-header {
  --el-header-padding: 0 !important;
  --el-header-height: 88px !important;
}

.el-main {
  --el-main-padding: 0px !important;
}

.el-table {
  .cell {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    white-space: pre-wrap !important;
  }
}

.el-form {
  .row {
    padding-top: 10px !important;

    .el-form-item {
      margin-bottom: 8px;
    }
  }
}

.el-image-viewer__mask {
  background: #fff !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.el-image-viewer__canvas {
  pointer-events: none !important;
}
</style>
