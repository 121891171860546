/**
 * Local Data storage management tool module
 */
import store from 'store';

const TOKEN_KEY = 'token_key';
const IDENTITY = 'identity_key'
const USER_ID = 'user_id'
const USER_NAME = 'user_name'

export default {
  saveToken(token) {
    store.set(TOKEN_KEY, token);
  },
  getToken() {
    return store.get(TOKEN_KEY) || '';
  },
  removeToken() {
    store.remove(TOKEN_KEY);
  },
  saveIdentity(identity) {
    store.set(IDENTITY, identity);
  },
  getIdentity() {
    return store.get(IDENTITY) || '';
  },
  removeIdentity() {
    store.remove(IDENTITY);
  },
  saveUserId(userId) {
    store.set(USER_ID, userId);
  },
  getUserId() {
    return store.get(USER_ID) || '';
  },
  removeUserId() {
    store.remove(USER_ID);
  },
  saveUserName(userName) {
    store.set(USER_NAME, userName);
  },
  getUserName() {
    return store.get(USER_NAME) || '';
  },
  removeUserName() {
    store.remove(USER_NAME);
  },
};
