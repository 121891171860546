import { defineStore } from "pinia";
import router from "../router";
export const useLabelStore = defineStore("label", {
  state: () => ({
    label: [],
    title: [],
    currentTag: { value: "" },
    lastTag: [],
    labelMatch: {
      "/dashboard": "数据总览",
      "/upstream": "上游-供应商",
      "/downstream": "下游-客户",
      "/logistics": "物流-第三方承运",
      "/target": "目标-待开发客户",
      "/message": "网站留言区",
      "/goodsname": "货物名称",
      "/goodscharact": "货物特性",
      "/purchasedata": "采购数据",
      "/saledata": "销售数据",
      "/dispatchdata": "调度数据",
      "/financedata": "财务数据",
      "/managerdata": "总经理/管理员数据",
      "/reconciliation/0": "销售对账单",
      "/reconciliation/1": "采购对账单",
      "/reconciliation/2": "运输对账单",
      "/reconciliation/3": "销售提成明细表",
      "/reconciliation/4": "采购提成明细表",
      "/reconciliation/5": "调度提成明细表",
      "/reconciliation/6": "采购服务费明细表",
      "/reconciliation/7": "销售服务费明细表",
      "/reconciliation/8": "危险化学品（采购）台账",
      "/reconciliation/9": "危险化学品（销售）台账",
      "/employ": "人员管理",
      "/statement/0": "总台账表",
      "/statement/1": "客户报表",
      "/statement/2": "供应商报表",
      "/statement/3": "销售人员报表",
      "/statement/4": "采购人员报表",
      "/statement/5": "调度人员报表",
      "/role": "角色管理",
    },
  }),
  actions: {
    addAction(route) {
      if (!this.labelMatch[route]) return;
      this.currentTag.value = this.labelMatch[route];
      if (!this.label.includes(route)) {
        this.title.push(this.labelMatch[route]);
        this.label.push(route);
      }
      const index = this.label.indexOf(route);
      const lastTagIndex = this.lastTag.indexOf(this.title[index]);
      if (lastTagIndex > -1) {
        this.lastTag.splice(lastTagIndex, 1);
      }
      this.lastTag.push(this.title[index]);
    },
    delAction(title) {
      let switchTitle;
      const index = this.title.indexOf(title);
      const lastTagIndex = this.lastTag.indexOf(title);
      if (this.lastTag.length > 1) {
        if (lastTagIndex === this.lastTag.length - 1) {
          switchTitle = this.lastTag[this.lastTag.length - 2];
        } else {
          switchTitle = this.lastTag[this.lastTag.length - 1];
        }
      } else {
        switchTitle = "数据总览";
      }
      this.lastTag.splice(lastTagIndex, 1);
      if (index > -1) {
        this.title.splice(index, 1);
        this.label.splice(index, 1);
      }
      this.switchAction(switchTitle);
    },
    switchAction(title) {
      let path;
      for (let key in this.labelMatch) {
        if (this.labelMatch[key] === title) {
          path = key;
        }
      }
      path && router.push({ path });
    },
  },
});
