import { createRouter, createWebHashHistory } from "vue-router";
import { useLabelStore } from "../stores/label";
import storage from "@utils/storageUtils";
const Home = () => import("../views/home");
const DashBoard = () => import("../views/dashBoard");
const Upstream = () => import("../views/customer/upstream");
const Downstream = () => import("../views/customer/downstream");
const Target = () => import("../views/customer/target");
const Logistics = () => import("../views/customer/logistics");
const Message = () => import("../views/customer/message");
const Goodsname = () => import("../views/goods/goodsname");
const Goodscharact = () => import("../views/goods/goodscharact");
const Purchasedata = () => import("../views/purchase/purchaseData");
const Saledata = () => import("../views/purchase/saledata");
const Dispatchdata = () => import("../views/purchase/dispatchdata");
const Financedata = () => import("../views/purchase/financedata");
const Managerdata = () => import("../views/purchase/managerdata");
const Reconciliation = () => import("../views/reconciliation");
const NotFound = () => import("../views/notFound");
const Employ = () => import("../views/employ");
const Statement = () => import("../views/statement");
const Login = () => import("../views/login");
const Role = () => import("../views/role");
const Log = () => import("../views/log");
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
      redirect: "/dashboard",
      children: [
        {
          path: "/dashBoard",
          component: DashBoard,
        },
        {
          path: "/upstream",
          component: Upstream,
        },
        {
          path: "/downstream",
          component: Downstream,
        },
        {
          path: "/target",
          component: Target,
        },
        {
          path: "/logistics",
          component: Logistics,
        },
        {
          path: "/message",
          component: Message,
        },
        {
          path: "/goodsname",
          component: Goodsname,
        },
        {
          path: "/goodscharact",
          component: Goodscharact,
        },
        {
          path: "/purchasedata",
          component: Purchasedata,
        },
        {
          path: "/saledata",
          component: Saledata,
        },
        {
          path: "/dispatchdata",
          component: Dispatchdata,
        },
        {
          path: "/financedata",
          component: Financedata,
        },
        {
          path: "/managerdata",
          component: Managerdata,
        },
        {
          path: "/reconciliation/:type",
          component: Reconciliation,
        },
        {
          path: "/employ",
          component: Employ,
        },
        {
          path: "/role",
          component: Role,
        },
        {
          path: "/loginfo",
          component: Log,
        },
        {
          path: "/statement/:type",
          component: Statement,
        },
      ],
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/:pathMatch(.*)*",
      component: NotFound,
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = storage.getToken();
  if (token || to.path === "/login") {
    const label = useLabelStore();
    label.addAction(to.path);
    next();
  } else {
    next("/login");
  }
});
export default router;
